import styled from "@emotion/styled"
import Link from "next/link"
import { LeftArrow, NewText, TransitionUpComponent, transitionDelayTime } from "ui"
import { sharedColors } from "ui/theme/colors"
import { extractProperty, getNonUmbracoUrl } from "utils"

const StyledItemDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 36px;
  gap: 12px;
  align-items: center;
  border-bottom: 2px solid #f7f7f7;
  padding-left: 8px;
  padding-right: 8px;
  &:last-child {
    border-bottom: none;
  }
`

const StyledTooltipADiv = styled.div<{
  $right: string
  $top: string
  $rightLG: string
  $topLG: string
}>`
  width: auto;
  height: auto;
  box-shadow: 0px 4px 12px 0px #0000003d;
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: white;
  min-width: 182px;
  position: absolute;
  right: ${(p) => p.$right};
  top: ${(p) => p.$top};

  ${(props) => props.theme.breakpoints.up("lg")} {
    right: ${(p) => p.$rightLG};
    top: ${(p) => p.$topLG};
  }
`

const StyledDot = styled.div<{ $background: string }>`
  background-color: ${(p) => p.$background};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
`

const StyledTextWrapp = styled.div`
  display: flex;
  align-items: center;
`

type Props = {
  countryItems: any
  inView: boolean
}

function EnergyMapDesktop({ countryItems, inView }: Props) {
  const getTooltipPosition = (index: number) => {
    if (index === 0) {
      return { right: "65%", top: "15%" }
    }
    if (index === 1) {
      return { right: "32%", top: "10%" }
    }
    if (index === 2) {
      return { right: "-1%", top: "10%" }
    }
    if (index === 3) {
      return { right: "59%", top: "45%" }
    }
  }

  const getTooltipPositionLG = (index: number) => {
    if (index === 0) {
      return { right: "68%", top: "20%" }
    }
    if (index === 1) {
      return { right: "35%", top: "10%" }
    }
    if (index === 2) {
      return { right: "3%", top: "10%" }
    }
    if (index === 3) {
      return { right: "59%", top: "45%" }
    }
  }

  return (
    <>
      {countryItems.map((item: any, index: number) => {
        const country = extractProperty(item, "country")
        const initiationAndDevelopment = extractProperty(item, "initiationAndDevelopment")
        const inOperation = extractProperty(item, "inOperation")
        const underConstruction = extractProperty(item, "underConstruction")
        const total = extractProperty(item, "total")
        const linkToProjectPage = extractProperty(item, "linkToProjectPage")
        const transitionDelay = transitionDelayTime * index
        return (
          <TransitionUpComponent inView={inView} delay={transitionDelay} key={index}>
            {(transitionStyles) => (
              <StyledTooltipADiv
                $right={getTooltipPosition(index)?.right || "unset"}
                $top={getTooltipPosition(index)?.top || "unset"}
                $rightLG={getTooltipPositionLG(index)?.right || "unset"}
                $topLG={getTooltipPositionLG(index)?.top || "unset"}
                style={{ ...transitionStyles }}
              >
                <StyledItemDiv>
                  <NewText
                    component={"p"}
                    desktopFontSize={14}
                    desktopLineHeight={"20px"}
                    desktopFontWeight={600}
                    color={sharedColors.black}
                  >
                    {country}
                  </NewText>
                  {!!linkToProjectPage.length && (
                    <Link href={getNonUmbracoUrl(linkToProjectPage[0]?.url)}>
                      <LeftArrow />
                    </Link>
                  )}
                </StyledItemDiv>
                {inOperation && (
                  <StyledItemDiv>
                    <StyledTextWrapp>
                      <StyledDot $background="#A6CF51" />
                      <NewText
                        component={"p"}
                        desktopFontSize={14}
                        desktopLineHeight={"20px"}
                        desktopFontWeight={400}
                        color={sharedColors.black}
                      >
                        בהפעלה
                      </NewText>
                    </StyledTextWrapp>
                    <NewText
                      component={"p"}
                      desktopFontSize={14}
                      desktopLineHeight={"20px"}
                      desktopFontWeight={400}
                      color={sharedColors.black}
                    >
                      {inOperation}
                    </NewText>
                  </StyledItemDiv>
                )}

                {underConstruction && (
                  <StyledItemDiv>
                    <StyledTextWrapp>
                      <StyledDot $background="#56CCF2" />
                      <NewText
                        component={"p"}
                        desktopFontSize={14}
                        desktopLineHeight={"20px"}
                        desktopFontWeight={400}
                        color={sharedColors.black}
                      >
                        בהקמה
                      </NewText>
                    </StyledTextWrapp>
                    <NewText
                      component={"p"}
                      desktopFontSize={14}
                      desktopLineHeight={"20px"}
                      desktopFontWeight={400}
                      color={sharedColors.black}
                    >
                      {underConstruction}
                    </NewText>
                  </StyledItemDiv>
                )}
                {initiationAndDevelopment && (
                  <StyledItemDiv>
                    <StyledTextWrapp>
                      <StyledDot $background="#FFD910" />
                      <NewText
                        component={"p"}
                        desktopFontSize={14}
                        desktopLineHeight={"20px"}
                        desktopFontWeight={400}
                        color={sharedColors.black}
                      >
                        בייזום ופיתוח
                      </NewText>
                    </StyledTextWrapp>
                    <NewText
                      component={"p"}
                      desktopFontSize={14}
                      desktopLineHeight={"20px"}
                      desktopFontWeight={400}
                      color={sharedColors.black}
                    >
                      {initiationAndDevelopment}
                    </NewText>
                  </StyledItemDiv>
                )}
                {total && (
                  <StyledItemDiv>
                    <StyledTextWrapp>
                      <NewText
                        component={"p"}
                        desktopFontSize={14}
                        desktopLineHeight={"20px"}
                        desktopFontWeight={600}
                        color={sharedColors.black}
                      >
                        סה״כ
                      </NewText>
                    </StyledTextWrapp>
                    <NewText
                      component={"p"}
                      desktopFontSize={14}
                      desktopLineHeight={"20px"}
                      desktopFontWeight={400}
                      color={sharedColors.black}
                    >
                      {total}
                    </NewText>
                  </StyledItemDiv>
                )}
              </StyledTooltipADiv>
            )}
          </TransitionUpComponent>
        )
      })}
    </>
  )
}

export default EnergyMapDesktop
