import styled from "@emotion/styled"
import Link from "next/link"
import { useState } from "react"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import { LeftArrow, NewText } from "ui"
import { sharedColors } from "ui/theme/colors"
import { extractProperty, getNonUmbracoUrl } from "utils"

const StyledSwiper = styled(Swiper)`
  padding-top: 32px;
  padding-bottom: 16px;
`

const StyledSwiperSlide = styled(SwiperSlide)``

const StyledItemDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 36px;
  gap: 12px;
  align-items: center;
  border-bottom: 2px solid #f7f7f7;
  padding-left: 8px;
  padding-right: 8px;
  &:last-child {
    border-bottom: none;
  }
`

const StyledDot = styled.div<{ $background: string }>`
  background-color: ${(p) => p.$background};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
`

const StyledTextWrapp = styled.div`
  display: flex;
  align-items: center;
`

const StyledTooltipADiv = styled.div`
  height: 185px;

  box-shadow: 0px 4px 12px 0px #0000003d;
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledBullet = styled.div<{ $active: boolean }>`
  width: 6px;
  height: 6px;
  border: 1px solid white;
  border-radius: 50%;
  background-color: ${(p) => (p.$active ? "white" : "transparant")};
`

const StyledDiv = styled.div``

const StyledBulletContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

const StyledTopTextWrapp = styled.div``

type Props = {
  countryItems: any
}

function EnergyMapMobile({ countryItems }: Props) {
  const [swiperRef, setSwiperRef] = useState<any>(null)
  const [activeIndex, setActiveIndex] = useState<number>(0)

  return (
    <StyledDiv>
      <StyledSwiper
        slidesPerView={1.6}
        spaceBetween={16}
        onSwiper={setSwiperRef}
        onSlideChange={(swiper) => {
          setActiveIndex(swiper.activeIndex)
        }}
      >
        {countryItems.map((item: any, index: number) => {
          const country = extractProperty(item, "country")
          const initiationAndDevelopment = extractProperty(item, "initiationAndDevelopment")
          const inOperation = extractProperty(item, "inOperation")
          const underConstruction = extractProperty(item, "underConstruction")
          const total = extractProperty(item, "total")
          const linkToProjectPage = extractProperty(item, "linkToProjectPage")
          return (
            <StyledSwiperSlide key={index}>
              <StyledTooltipADiv>
                <StyledTopTextWrapp>
                  <StyledItemDiv>
                    <NewText
                      component={"p"}
                      desktopFontSize={14}
                      desktopLineHeight={"20px"}
                      desktopFontWeight={600}
                      color={sharedColors.black}
                    >
                      {country}
                    </NewText>
                    {!!linkToProjectPage.length && (
                      <Link href={getNonUmbracoUrl(linkToProjectPage[0]?.url)}>
                        <LeftArrow />
                      </Link>
                    )}
                  </StyledItemDiv>
                  {inOperation && (
                    <StyledItemDiv>
                      <StyledTextWrapp>
                        <StyledDot $background="#A6CF51" />
                        <NewText
                          component={"p"}
                          desktopFontSize={14}
                          desktopLineHeight={"20px"}
                          desktopFontWeight={400}
                          color={sharedColors.black}
                        >
                          בהפעלה
                        </NewText>
                      </StyledTextWrapp>
                      <NewText
                        component={"p"}
                        desktopFontSize={14}
                        desktopLineHeight={"20px"}
                        desktopFontWeight={400}
                        color={sharedColors.black}
                      >
                        {inOperation}
                      </NewText>
                    </StyledItemDiv>
                  )}

                  {underConstruction && (
                    <StyledItemDiv>
                      <StyledTextWrapp>
                        <StyledDot $background="#56CCF2" />
                        <NewText
                          component={"p"}
                          desktopFontSize={14}
                          desktopLineHeight={"20px"}
                          desktopFontWeight={400}
                          color={sharedColors.black}
                        >
                          בהקמה
                        </NewText>
                      </StyledTextWrapp>
                      <NewText
                        component={"p"}
                        desktopFontSize={14}
                        desktopLineHeight={"20px"}
                        desktopFontWeight={400}
                        color={sharedColors.black}
                      >
                        {underConstruction}
                      </NewText>
                    </StyledItemDiv>
                  )}
                  {initiationAndDevelopment && (
                    <StyledItemDiv>
                      <StyledTextWrapp>
                        <StyledDot $background="#FFD910" />
                        <NewText
                          component={"p"}
                          desktopFontSize={14}
                          desktopLineHeight={"20px"}
                          desktopFontWeight={400}
                          color={sharedColors.black}
                        >
                          בייזום ופיתוח
                        </NewText>
                      </StyledTextWrapp>
                      <NewText
                        component={"p"}
                        desktopFontSize={14}
                        desktopLineHeight={"20px"}
                        desktopFontWeight={400}
                        color={sharedColors.black}
                      >
                        {initiationAndDevelopment}
                      </NewText>
                    </StyledItemDiv>
                  )}
                </StyledTopTextWrapp>
                {total && (
                  <StyledItemDiv>
                    <StyledTextWrapp>
                      <NewText
                        component={"p"}
                        desktopFontSize={14}
                        desktopLineHeight={"20px"}
                        desktopFontWeight={600}
                        color={sharedColors.black}
                      >
                        סה״כ
                      </NewText>
                    </StyledTextWrapp>
                    <NewText
                      component={"p"}
                      desktopFontSize={14}
                      desktopLineHeight={"20px"}
                      desktopFontWeight={400}
                      color={sharedColors.black}
                    >
                      {total}
                    </NewText>
                  </StyledItemDiv>
                )}
              </StyledTooltipADiv>
            </StyledSwiperSlide>
          )
        })}
      </StyledSwiper>
      <StyledBulletContainer>
        {countryItems.map((_: any, index: number) => (
          <StyledBullet
            key={index}
            onClick={() => {
              swiperRef.slideTo(index)
            }}
            $active={index === activeIndex}
          />
        ))}
      </StyledBulletContainer>
    </StyledDiv>
  )
}

export default EnergyMapMobile
