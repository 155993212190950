"use client"

import styled from "@emotion/styled"
import React from "react"
import { useInView } from "react-intersection-observer"
import { Container, NewText, useInViewConfig, useTransitionUpStyles } from "ui"
import { sharedColors as colors } from "ui/theme/colors"
import { delayTime, styleObjectToCss } from "ui/transitions/utils"
import { extractProperty } from "utils"

const StyledContainer = styled.div<{ $width?: string }>`
  max-width: 700px;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  p {
    margin: 0px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 32px;
    max-width: ${(p) => p.$width};
  }
`

const StyledBorder = styled.div`
  border-bottom: 2px solid #f7f7f7;
`

type Props = {
  data: any
  component?: React.ElementType<any>
  scrollDown: string
  width?: string
}

export const TitleContent: React.FC<Props> = ({
  data,
  component = "h1",
  width = "700px",
}: Props) => {
  const { ref: titleRef, inView } = useInView(useInViewConfig)
  const title = extractProperty(data, "title")
  const innerText = extractProperty(data, "content")
  const titleTransitionStyles = useTransitionUpStyles(inView)
  const descriptionTransitionStyles = useTransitionUpStyles(inView, delayTime)

  return (
    <StyledBorder>
      <Container>
        <StyledContainer ref={titleRef} $width={width}>
          <NewText
            color={colors.black}
            component={component}
            fontWeight="600"
            mobileFontSize={40}
            mobileLineHeight="44px"
            mobileFontWeight="600"
            desktopFontWeight="600"
            desktopFontSize={48}
            desktopLineHeight="60px"
            cssString={styleObjectToCss(titleTransitionStyles)}
          >
            {title}
          </NewText>

          <NewText
            color={colors.black}
            component="div"
            mobileFontSize={16}
            mobileLineHeight="23px"
            desktopFontSize={18}
            desktopLineHeight="26px"
            dangerouslySetInnerHTML={{ __html: innerText }}
            cssString={styleObjectToCss(descriptionTransitionStyles)}
            maxWidth={"800px"}
          />
        </StyledContainer>
      </Container>
    </StyledBorder>
  )
}
