"use client"
import styled from "@emotion/styled"

import { Button, IconButton } from "@mui/material"
import { useLocale } from "next-intl"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/navigation"
import { useInView } from "react-intersection-observer"
import "swiper/css"
import {
  Desktop,
  LeftArrow,
  LocationIcon,
  Mobile,
  NewText,
  RightArrow,
  useInViewConfig,
  useIsDesktop,
} from "ui"
import { getNonUmbracoUrl, getUmbracoMediaUrl, pageStripClick } from "utils"

const StyledInfoContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px;
  z-index: 30;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  flex-grow: 0 !important;
  border: 1px solid #6f6f6f;
  ${(props) => props.theme.breakpoints.up("md")} {
    background-color: rgba(255, 255, 255, 0.88);
    flex-shrink: 0;
    padding-bottom: 0;
  }
`
const StyledInfoBottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const StyledPaginatorContainer = styled.div`
  position: absolute;
  top: 19px;
  left: 16px;
  z-index: 10;
  background-color: white;
  padding: 10px;
  border-radius: 200px;
  svg {
    color: ${(props) => props.theme.colors.black};
  }
  button {
    width: 22px;
    height: 22px;
    padding: unset;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 0;
    background-color: unset;
  }
`

const StyledBulletsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 4px;
`

const StyledBullet = styled.div<{ active: boolean }>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin: 0 8px;
  cursor: pointer;
  border: ${(props) => (props.active ? "2.33px solid #2768A3" : "2.33px solid black")};
  background-color: ${(props) => (props.active ? "#2768A3" : "none")};
  :last-child {
    margin-right: 0px;
  }
  :first-child {
    margin-left: 0px;
  }
`
const StyledLocationItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:last-child {
    justify-content: flex-end;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    min-width: 250px;
  }
  button {
    padding: 0px;
    font-size: 16px;
    svg {
      margin-left: 8px;
    }
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 18px;
    }
  }
`

type Props = {
  swiperRef: any
  activeIndex: number
  item: any
  slides: any
  culture: any
  title?: string
}

function ProjectSlide({ swiperRef, activeIndex, item, slides, culture, title }: Props) {
  const isDesktop = useIsDesktop()
  const { ref, inView } = useInView(useInViewConfig)
  const { ref: mobileRef, inView: mobileInView } = useInView(useInViewConfig)
  const locale = useLocale()
  const router = useRouter()
  return (
    <>
      <>
        <Desktop>
          <Image
            ref={ref}
            src={getUmbracoMediaUrl(item?.properties?.heroBannerDesktop)}
            alt={item?.properties?.projectTitle}
            fill
            style={{
              objectFit: "cover",
            }}
            loading={inView ? "eager" : "lazy"}
          />
        </Desktop>
        <Mobile>
          <Image
            ref={mobileRef}
            src={getUmbracoMediaUrl(item?.properties?.heroBannerMobile)}
            alt={item?.properties?.projectTitle}
            fill
            style={{
              objectFit: "cover",
            }}
            loading={mobileInView ? "eager" : "lazy"}
          />
        </Mobile>
      </>

      {!isDesktop && (
        <StyledInfoContainer>
          <NewText
            desktopFontSize={32}
            desktopFontWeight="400"
            desktopLineHeight="36px"
            mobileFontSize={24}
            mobileFontWeight="400"
            mobileLineHeight="34.56px"
            component="h3"
            color={"black"}
            marginLeft="4px"
          >
            {slides[activeIndex]?.properties?.projectTitle}
          </NewText>
          <StyledInfoBottomContainer>
            <StyledLocationItem>
              <LocationIcon />
              <NewText
                desktopFontSize={14}
                desktopFontWeight="400"
                desktopLineHeight="20px"
                mobileFontSize={18}
                mobileFontWeight="400"
                mobileLineHeight="17px"
                component="p"
                color="black"
                marginLeft="4px"
              >
                {slides[activeIndex]?.properties?.location}
              </NewText>
            </StyledLocationItem>
            <StyledLocationItem>
              <Link
                href={getNonUmbracoUrl(item.url)}
                title={slides[activeIndex]?.properties?.projectTitle}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "black",
                }}
                onClick={() => pageStripClick(slides[activeIndex]?.properties?.projectTitle, title)}
              >
                <Button
                  variant="text"
                  size="medium"
                  tabIndex={-1}
                  style={{
                    textDecoration: "none",
                    padding: 4,
                    margin: 0,
                    color: "black",

                    lineHeight: "22px",
                  }}
                >
                  {locale === "he" ? "לפרויקט" : "To project"}
                </Button>
                {culture === "he-il" ? <LeftArrow /> : <RightArrow />}
              </Link>
            </StyledLocationItem>
          </StyledInfoBottomContainer>
        </StyledInfoContainer>
      )}
      {isDesktop && (
        <StyledInfoContainer>
          <NewText
            desktopFontSize={32}
            desktopFontWeight="400"
            desktopLineHeight="36px"
            mobileFontSize={18}
            mobileFontWeight="400"
            mobileLineHeight="25.92px"
            component="h3"
            color="black"
            marginLeft="4px"
          >
            {slides[activeIndex]?.properties?.projectTitle}
          </NewText>
          <StyledInfoBottomContainer>
            <StyledLocationItem>
              <LocationIcon />
              <NewText
                desktopFontSize={16}
                desktopFontWeight="400"
                desktopLineHeight="23px"
                mobileFontSize={14}
                mobileFontWeight="400"
                mobileLineHeight="20px"
                component="p"
                color="black"
                marginLeft="4px"
                width="250px"
                whiteSpace="nowrap"
              >
                {slides[activeIndex]?.properties?.location}
              </NewText>
            </StyledLocationItem>
            <StyledPaginatorContainer>
              <Desktop>
                <IconButton
                  disabled={activeIndex === 0}
                  onClick={() => {
                    swiperRef.slidePrev()
                  }}
                  style={activeIndex === 0 ? { opacity: 0.5 } : {}}
                  aria-label={`${locale === "he" ? "חזור לתמונה הקודמת " : "go to previous slide"}`}
                >
                  {culture === "he-il" ? <RightArrow /> : <LeftArrow />}
                </IconButton>
              </Desktop>
              <StyledBulletsContainer>
                {slides.map((_: any, index: number) => (
                  <StyledBullet
                    key={index.toString()}
                    onClick={() => {
                      swiperRef.slideTo(index)
                    }}
                    active={index === activeIndex}
                  />
                ))}
              </StyledBulletsContainer>
              <Desktop>
                <IconButton
                  onClick={() => {
                    swiperRef.slideNext()
                  }}
                  disabled={activeIndex === slides.length - 1}
                  style={activeIndex === slides.length - 1 ? { opacity: 0.5 } : {}}
                  aria-label={`${locale === "he" ? "עבור לתמונה הבאה" : "go to next slide"}`}
                >
                  {culture === "he-il" ? <LeftArrow /> : <RightArrow />}
                </IconButton>
              </Desktop>
            </StyledPaginatorContainer>
            <StyledLocationItem>
              <Button
                role="link"
                size="large"
                title={slides[activeIndex]?.properties?.projectTitle}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",

                  lineHeight: "22px",
                }}
                sx={{
                  s: { fontSize: "16px" },
                  m: { fontSize: "18px" },
                }}
                onClick={() => {
                  pageStripClick(slides[activeIndex]?.properties?.projectTitle, title)
                  router.push(getNonUmbracoUrl(item.url))
                }}
              >
                {locale === "he" ? "לפרויקט" : "To project"}
                {culture === "he-il" ? <LeftArrow /> : <RightArrow />}
              </Button>
            </StyledLocationItem>
          </StyledInfoBottomContainer>
        </StyledInfoContainer>
      )}
    </>
  )
}

export default ProjectSlide
